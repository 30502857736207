import React from 'react'
const slides = [
    {
        "href": "https://dot.gov.in/tec",
        "src": "https://dot.gov.in/sites/default/files/TEC-Logo.jpg",
        "width": 1200,
        "height": 675,
        "alt": "TEC | External link that open in new window",
        "title": "TEC | External link that open in new window"
    },
    {
        "href": "https://dot.gov.in/usof",
        "src": "https://dot.gov.in/sites/default/files/logo-usof.png",
        "width": 134,
        "height": 69,
        "alt": "USOF | External link that open in new window",
        "title": "USOF | External link that open in new window"
    },
    {
        "href": "https://dot.gov.in/cdot-0",
        "src": "https://dot.gov.in/sites/default/files/cdot_logo.png",
        "width": 249,
        "height": 437,
        "alt": "C-DOT | External link that open in new window",
        "title": "C-DOT | External link that open in new window"
    },
    {
        "href": "https://dot.gov.in/national-single-window-system",
        "src": "https://dot.gov.in/sites/default/files/logo_nsws.png",
        "width": 3000,
        "height": 800,
        "alt": "National Single Window System",
        "title": "NSWS"
    },
    {
        "href": "https://dot.gov.in/tafcop",
        "src": "https://dot.gov.in/sites/default/files/taf-cop-logo_1.png",
        "width": 356,
        "height": 80,
        "alt": "TAFCOP",
        "title": "Telecom Analytics for Fraud management and Consumer Protection"
    },
    {
        "href": "https://dot.gov.in/cyber-dost",
        "src": "https://dot.gov.in/sites/default/files/cyberdost%20logo_1.png",
        "width": 89,
        "height": 91,
        "alt": "Cyber Dost",
        "title": "Cyber Dost"
    },

]
const slides2 = [
    {
        "href": "https://dot.gov.in/dot-dashboard",
        "src": "https://dot.gov.in/sites/default/files/Dashboard.png",
        "width": 173,
        "height": 121,
        "alt": "DoT Performance Dashboard",
        "title": "DoT Performance Dashboard"
    },
    {
        "href": "https://dot.gov.in/investment-grid",
        "src": "https://dot.gov.in/sites/default/files/Investment%20Grid.png",
        "width": 141,
        "height": 42,
        "alt": "Investment Grid",
        "title": "Investment Grid"
    },
    {
        "href": "https://dot.gov.in/saral-sanchar",
        "src": "https://dot.gov.in/sites/default/files/SaralSanchar.png",
        "width": 100,
        "height": 75,
        "alt": "Saral Sanchar",
        "title": "Saral Sanchar"
    },
    {
        "href": "https://dot.gov.in/150th-birth-anniversary-mahatma-gandhi",
        "src": "https://dot.gov.in/sites/default/files/150%20Anniversary.png",
        "width": 1200,
        "height": 675,
        "alt": "150th Birth Anniversary of Mahatma Gandhi",
        "title": "150th Birth Anniversary of Mahatma Gandhi"
    },
    {
        "href": "https://dot.gov.in/national-government-services-portal",
        "src": "https://dot.gov.in/sites/default/files/banner_199_87_color.jpg",
        "width": 199,
        "height": 87,
        "alt": "National Government Service Portal",
        "title": "National Government Service Portal"
    },
    {
        "href": "https://dot.gov.in/icis",
        "src": "https://dot.gov.in/sites/default/files/ICIS-new1.png",
        "width": 120,
        "height": 60,
        "alt": "ICIS",
        "title": "ICIS"
    },
    {
        "href": "https://dot.gov.in/trai-analytics",
        "src": "https://dot.gov.in/sites/default/files/trai-analytics.png",
        "width": 200,
        "height": 150,
        "alt": "TRAI Analytics | External link that open in new window",
        "title": "TRAI Analytics | External link that open in new window"
    },
    {
        "href": "https://dot.gov.in/tarang-sanchar",
        "src": "https://dot.gov.in/sites/default/files/tarang_sanchar.png",
        "width": 200,
        "height": 150,
        "alt": "Tarang Sanchar | External link that open in new window",
        "title": "Tarang Sanchar | External link that open in new window"
    },
    // {
    //     "href": "https://dot.gov.in/sparror-dot-e-office",
    //     "src": "https://dot.gov.in/sites/default/files/SPARROW%28DoT%29_0.png",
    //     "width": 960,
    //     "height": 720,
    //     "alt": "SPARROW-DoT | External link that open in new window",
    //     "title": "SPARROW-DoT | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/press-information-bureau",
    //     "src": "https://dot.gov.in/sites/default/files/PIB-Footer-banner.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Press Information Bureau | External link that open in new window",
    //     "title": "Press Information Bureau | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/saras",
    //     "src": "https://dot.gov.in/sites/default/files/SARAS%20logo%20final.png",
    //     "width": 172,
    //     "height": 102,
    //     "alt": "SARAS Portal",
    //     "title": "SARAS Portal"
    // },
    // {
    //     "href": "https://dot.gov.in/public-financial-management-system",
    //     "src": "https://dot.gov.in/sites/default/files/footer-slider-3.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Public Financial Management System (PFMS) | External link that open in new window",
    //     "title": "Public Financial Management System (PFMS) | External link that open in new window"
    // },
    // {
    //     "href": "http://dot.attendance.gov.in/",
    //     "src": "https://dot.gov.in/sites/default/files/footer-slider-4.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Aadhaar Enabled Biometric Attendance System (AEBAS) | External link that open in new window",
    //     "title": "Aadhaar Enabled Biometric Attendance System (AEBAS) | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/data-gov",
    //     "src": "https://dot.gov.in/sites/default/files/data-gov_0.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Data Gov | External link that open in new window",
    //     "title": "Data Gov | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/make-india",
    //     "src": "https://dot.gov.in/sites/default/files/makeinindia.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Make in India | External link that open in new window",
    //     "title": "Make in India | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/incredible-india",
    //     "src": "https://dot.gov.in/sites/default/files/incredible-india.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Incredible India | External link that open in new window",
    //     "title": "Incredible India | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/my-gov",
    //     "src": "https://dot.gov.in/sites/default/files/mygov.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "My gov | External link that open in new window",
    //     "title": "My gov | External link that open in new window"
    // },
    // {
    //     "href": "https://dot.gov.in/prime-minister-national-relief-fund",
    //     "src": "https://dot.gov.in/sites/default/files/pmnrf.png",
    //     "width": 200,
    //     "height": 150,
    //     "alt": "Prime Minister National Relief Fund | External link that open in new window",
    //     "title": "Prime Minister National Relief Fund | External link that open in new window"
    // },
]

const LogoSlider = () => {
    return (
        <div className="relative flex overflow-x-hidden bg-white">
            <section className="animate-marquee container mx-auto flex flex-row overflow-hidden gap-5 justify-between items-center py-4 bg-white bg-opacity-60 whitespace-nowrap">
                {slides.map((item) => {
                    return <a href={item.href} key={item.href}>
                        <img
                            loading="lazy"
                            src={item.src}
                            className="object-contain shrink-0 self-stretch my-auto aspect-[1.79] w-[150px]"
                            alt={item.alt}
                        />
                    </a>
                })}
                {/* <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dbb373ceeb8aeb8600c178fc702e9889e545810e5d2efc79c1de011a7c8c6cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.79] w-[150px]"
                    alt="Company logo"
                />
                <div className="flex gap-2.5 justify-center items-center self-stretch my-auto w-[150px]">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7fc312f619558f7c636b0addb1d2a0f343f70d43e5992703207dc65e6745987?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain self-stretch my-auto aspect-[0.57] w-[47px]"
                        alt="Partner logo"
                    />
                </div>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cfc060368c6d71702bafc37f55b958d9fa69ba090649825e8007dfbf04ea15e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[2.68] w-[150px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/59a4ad25f190d06b95b54ffbbae72003e6f17aa47bf966711f39890f4503e0f6?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.75] w-[150px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e77fed780e961b626790ba6e788332f86959f1a9e713027895586f45b7763d?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.34] w-[150px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/56e761a36091a5a7e9c7bf571b3c194823e8db0431c37ca30f7fd46aa64240a4?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[0.82] w-[69px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4c8a2929f8dd160eb59499860d567facd43a54186855a4498a6bf76595eaf4e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/518af0e7e0a9cf210d7a900a5851568b58c6c17d6c679517d76b018db8ee05cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                    alt="Partner logo"
                /> */}
            </section>
            <section className="container mx-auto flex flex-row overflow-hidden gap-5 justify-between items-center py-4 bg-white bg-opacity-60 absolute top-0 animate-marquee2 whitespace-nowrap">
                {slides2.map((item) => {
                    return <a href={item.href} key={item.href} title={item.title}>
                        <img
                            loading="lazy"
                            src={item.src}
                            className="object-contain shrink-0 self-stretch my-auto aspect-[1.79] w-[150px]"
                            alt={item.alt}
                        />
                    </a>
                })}
                {/* <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dbb373ceeb8aeb8600c178fc702e9889e545810e5d2efc79c1de011a7c8c6cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.79] w-[150px]"
                    alt="Company logo"
                />
                <div className="flex gap-2.5 justify-center items-center self-stretch my-auto w-[150px]">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7fc312f619558f7c636b0addb1d2a0f343f70d43e5992703207dc65e6745987?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain self-stretch my-auto aspect-[0.57] w-[47px]"
                        alt="Partner logo"
                    />
                </div>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cfc060368c6d71702bafc37f55b958d9fa69ba090649825e8007dfbf04ea15e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[2.68] w-[150px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/59a4ad25f190d06b95b54ffbbae72003e6f17aa47bf966711f39890f4503e0f6?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[3.75] w-[150px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e77fed780e961b626790ba6e788332f86959f1a9e713027895586f45b7763d?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.34] w-[150px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/56e761a36091a5a7e9c7bf571b3c194823e8db0431c37ca30f7fd46aa64240a4?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[0.82] w-[69px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4c8a2929f8dd160eb59499860d567facd43a54186855a4498a6bf76595eaf4e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                    alt="Partner logo"
                />
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/518af0e7e0a9cf210d7a900a5851568b58c6c17d6c679517d76b018db8ee05cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                    alt="Partner logo"
                /> */}
            </section>
        </div>
    )
}

export default LogoSlider