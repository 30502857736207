import React, { useState } from 'react'
import { dotProducts } from './Header'

const DotLinks = () => {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <div className='self-stretch my-auto  w-full'>
            <button onClick={() => {
                setShowMenu((b) => !b)
            }}>
                DotLinks
            </button>
            {showMenu &&
                <div className='bg-gray-100 w-full mt-1 divide-y'>
                    {dotProducts.map((item) => {
                        return <a href={item.href} title={item.title} key={item.href} target='_blank' className='p-2 whitespace-nowrap  w-full hover:bg-gray-100 block px-4'>{item.text}</a>
                    })}
                </div>
            }
        </div>
    )
}

export default DotLinks