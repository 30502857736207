import React from 'react';
import TowerImg from "../../../assets/images/tower-img.svg"
const About = () => {
    return (
        <section className="flex overflow-x-hidden flex-col relative py-16 px-4" id='about'>
            <div className="w-[500px] h-[500px] opacity-50 bg-[#50c69c] rounded-full blur-[500px] absolute -right-1/2 -top-1" />
            <div className="container px-2 mx-auto overflow-hidden">
                <div className="flex flex-wrap gap-10 items-center w-full max-md:max-w-full flex-col sm:flex-row">

                    <div className="flex-1 flex flex-col grow shrink self-stretch my-auto min-w-[240px] w-[800px] max-md:max-w-full order-2 sm:order-1 ">
                        <div className="flex flex-col max-md:max-w-full">
                            <div className=" flex-col justify-start items-start gap-8 inline-flex">
                                <div className="self-stretch  flex-col justify-start items-start flex">
                                    <div className="self-stretch text-[#5d50c6] text-[23px] font-bold font-['Poppins'] uppercase leading-7 tracking-[4.60px]">
                                        About
                                    </div>
                                    <div className="self-stretch text-[#191825] text-[44px] font-bold font-['Poppins'] leading-[52.80px]">
                                        eTT Centre
                                    </div>
                                </div>
                                <div className="w-full text-black font-normal font-['Inter'] leading-[28.80px] text-justify">
                                    e Telecom Transformation (eTT) Centre is an Independent Business Vertical (IBV) within CDOT, setup by the Department of Telecommunications, Ministry of Communications, Government of India. eTT centre has been created and mandated to develop a Unified Portal and manage the same and do the digital by design implementation of the new Telecommunications Act 2023.
                                </div>
                            </div>
                        </div>
                        <div className="py-2.5 flex-col justify-center items-center gap-2.5 inline-flex">
                            <div className="self-stretch flex-col justify-start items-start flex">
                                <div className="self-stretch flex-col justify-start items-start flex">
                                    <div className="mt-5 self-stretch text-[#5d50c6] text-[23px] font-bold font-['Poppins'] uppercase leading-7 tracking-[4.60px]">
                                        The goals and envisioned
                                    </div>
                                    <div className="self-stretch text-[#191825] text-[44px] font-bold font-['Poppins'] leading-[52.80px]">
                                        Roles and Responsibilities
                                    </div>
                                </div>
                            </div>
                            <div className="text-[#191825]  font-normal font-['Inter'] leading-[28.80px]">
                                <ul className='list-disc pl-5 mt-3 gap-y-2 flex flex-col text-justify'>
                                    <li>To modernize telecom licensing operations with a enterprise grade
                                        Unified Portal built using cutting-edge technologies. The Unified
                                        Portal shall not only meet the evolving regulatory requirements
                                        but also enhance the overall experience for all users and
                                        stakeholders. This initiative will enhance efficiency in licensee
                                        lifecycle management while aligning with the forward-looking
                                        provisions of the New Telecommunication Act 2023 to ensure Ease Of
                                        Doing Business.
                                    </li>
                                    <li> Assist DOT not only in creation of this Unified Portal, but in key
                                        roles and tasks associated with the project management of various
                                        projects of DOT like TTDF initiatives, IPR facilitation and
                                        digital by design implementation of the new Telecom Act and any
                                        other work/project assigned by DOT.
                                    </li>
                                    <li>Bring the focus and expertise in the overall work, and result in
                                        better implementation and administration of the initiatives.

                                    </li>
                                    <li>Enable the government to facilitate quick deployment of telecom
                                        infrastructure, thus bringing Government and its services closer
                                        to the common man and will also give a flip to Telecom
                                        manufacturing activity in the country with the ultimate goals to
                                        the contribution of the economic transformation of India.
                                    </li>
                                    <li>Enhance better visibility by nicely designed interfaces and
                                        sharable graphs/charts etc.
                                    </li>
                                    <li>Conduct a detailed feasibility study to assess the scope, budget,
                                        and timeframe for any new project/development.
                                    </li>
                                    <li>Extend PMU support to DOT, in business process reorientation,
                                        preparation of Schedule of requirements, conducting tests and
                                        validation etc.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 flex grow shrink gap-2.5 items-start self-stretch my-auto min-w-[240px] max-md:max-w-full order-1 sm:order-2 ">
                        <img
                            loading="lazy"
                            src={TowerImg}
                            alt="About Us image"
                            className="object-contain rounded-none aspect-[0.73] min-w-[240px]"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About