import React, { useState } from 'react'
import logo from "../../assets/icons/hamburger-md-svgrepo-com.svg";
import CaretDown from "../../assets/icons/caret-down.svg"
import DotLinks from './DotLinks';
interface MenuItem {
    href: string;
    text: string;
    title?: string;
}

export const dotProducts: MenuItem[] = [
    {
        href: 'https://bharatnetlive.bbnlindia.in/UNMS/index.jsp',
        text: 'BharatNet Dashboard',
    },
    {
        href: 'https://sancharsaathi.gov.in/',
        text: 'SancharSaathi',
        title:
            'Sanchar Saathi portal is a citizen-centric initiative of Department of Telecommunications to empower mobile subscribers...',
    },
    {
        href: 'https://www.mtcte.tec.gov.in/',
        text: 'MTCTE Portal',
        title:
            'Portal for Mandatory Testing and Certification of Telecommunication Equipment...',
    },
    {
        href: 'https://gatishaktisanchar.gov.in/',
        text: 'GatiShakti Sanchar Portal',
        title:
            'GatiShakti Sanchar Portal, designed and developed by Department of Telecommunications...',
    },
    {
        href: 'https://pmwani.gov.in/wani',
        text: 'PM-WANI Portal',
        title:
            "Prime Minister's WiFi Access Network Interface (PM-WANI) was launched by the Department of Telecommunication...",
    },
    {
        href: 'https://usof.gov.in/',
        text: 'USOF Portal',
    },
    {
        href: 'https://dot.dashboard.nic.in',
        text: 'DoT Dashboard',
    },
    {
        href: 'https://pli-telecom.udyamimitra.in',
        text: 'Telecom PLI Scheme',
        title:
            'The Production Linked Incentive (PLI) Scheme will promote Telecom and Networking Products manufacturing in India...',
    },
    {
        href: 'https://www.saras.gov.in/',
        text: 'Saras Portal',
        title:
            'A Public Utility Portal for Revenue Management System, for use by all Licensees across India...',
    },
    {
        href: 'https://saralsanchar.gov.in/',
        text: 'Saral Sanchar',
        title:
            'A Public Utility Portal for License Management in DoT using Simplified Application for Registration and Licenses...',
    },
    {
        href: 'https://tarangsanchar.gov.in/EMFPortal/Home',
        text: 'Tarang Sanchar',
        title: 'Portal for information sharing on mobile towers and EMF compliance.',
    },
    {
        href: 'https://dotpension.gov.in/Login/Index',
        text: 'DoT Pension Portal',
    },
];
const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    console.log({ showMenu })
    return (
        <header className="border-b w-full flex flex-wrap justify-between items-center py-2.5 shadow-[0px_4px_4px_rgba(0,0,0,0.2)] bg-white sticky top-0 z-10">
            <div className="container max-w-7xl mx-auto flex justify-between">
                <div className="flex flex-wrap gap-7 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
                    <div className='bg-gray-200'></div>eTT Logo
                    {/* <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cd829b8752679e79c0c465b7585e7553ba0f393dbe5984e3d323f39f9da171c?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        alt="Company logo"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[2.48] w-[198px]"
                    /> */}
                    <div className="gap-4 items-center self-stretch my-auto min-w-[240px] hidden sm:flex">
                        <a href='https://dot.gov.in/' className='flex gap-4'>
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a45ec0fb2c1bf15ffd7872a386633834348f7eb51c9fbe510d22d6d3f5c83f4?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                                alt="Secondary logo"
                                className="object-contain self-stretch my-auto aspect-[3.6] min-w-[200px] w-[200px]"
                            />
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/43157b4cba087683a7526d291a3d3eb12f1ddcba7ec18f2435f07560fb940163?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                                alt="Tertiary logo"
                                className="object-contain shrink-0 self-stretch my-auto aspect-[0.91] w-[63px]"
                            />
                        </a>
                    </div>
                </div>
                <button onClick={() => {
                    setShowMenu((b) => !b);
                }} className="w-16 h-16 flex items-center justify-center p-2">
                    <img
                        src={logo}
                        className="w-full h-full md:hidden"
                        alt="menu"
                    />
                </button>
                <nav className="hidden md:flex flex-wrap gap-10 justify-center items-center self-stretch my-auto text-base font-medium leading-tight min-w-[240px] text-gray-900 text-opacity-50 max-md:max-w-full ml-10">
                    <a
                        href="#"
                        className="gap-2.5 self-stretch px-6 py-2.5 my-auto text-white whitespace-nowrap bg-indigo-900 rounded-[29px] max-md:px-5"
                    >
                        Home
                    </a>
                    <a href="#about" className="self-stretch my-auto">
                        About eTT
                    </a>
                    <div className="relative group">
                        <div className='self-stretch my-auto flex gap-2'>
                            DOT Links
                            <img src={CaretDown} />
                        </div>
                        <div className="absolute w-[230%] invisible -left-24 group-hover:visible bg-white shadow-md rounded overflow-hidden divide-y border">
                            {dotProducts.map((item) => {
                                return <a href={item.href} title={item.title} key={item.href} target='_blank' className='p-2 whitespace-nowrap bg-white w-full hover:bg-gray-100 block'>{item.text}</a>
                            })}
                        </div>
                    </div>
                </nav>

            </div>
            {/* mobile Nav */}
            {showMenu && <nav className=" flex flex-col flex-wrap gap-6 mt-4 justify-center items-center self-stretch my-auto text-base font-medium leading-tight min-w-[240px] text-gray-900 text-opacity-50 max-md:max-w-full ml-10 w-[85%]">
                <a
                    href="#"
                    className="gap-2.5 self-stretch px-6 py-2.5  text-white whitespace-nowrap bg-indigo-900 rounded-[29px] max-md:px-5"
                >
                    Home
                </a>
                <a href="#about" className="self-stretch my-auto">
                    About
                </a>
                <DotLinks />
            </nav>}
        </header>
    )
}

export default Header