const HeroSlider = () => {
    // const settings: Settings = {
    //     dots: true,
    //     fade: true,
    //     infinite: false,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    // };
    return (
        <section className=" px-4 mx-auto relative overflow-hidden">
            <div className="w-[400px] h-[200px] sm:w-[500px] sm:h-[500px] bg-[#ff5722] rounded-full blur-[500px] absolute -left-1/2 -top-1/2 -z-10" />
            <div className='container mx-auto'>
            {/* <Slider {...settings}> */}
                <div className='flex flex-col sm:flex-row gap-10 items-center   relative '>
                    <div className='flex flex-col sm:flex-row gap-10 items-center  my-8 relative'>
                        <div className="sm:w-3/5 flex flex-col self-stretch my-auto min-w-[240px] max-md:max-w-full">
                            <h2 className="text-6xl font-bold leading-10 max-md:max-w-full max-md:text-4xl md:leading-[82px]">
                                <span className="text-indigo-900">Empowering </span>
                                <span className="text-indigo-600">Connectivity</span>
                                <br />
                                <span className="text-indigo-900">Innovating </span>
                                <span className="text-indigo-600">Tomorrow</span>
                            </h2>
                            <p className="mt-4 leading-7 text-gray-900 max-md:mt-5 max-md:max-w-full">
                                <strong>e-Telecom Transformation Centre- eTT</strong> (Formally named as
                                Tele Tech), recently setup by Department of Telecommunication, Ministry
                                of Communications, Government of India.
                            </p>
                            <a
                                href="#about"
                                className="inline-block gap-2.5 self-start px-8 py-2 mt-4 leading-relaxed text-white bg-indigo-600 rounded-[35px] max-md:px-5 max-md:mt-10"
                                role="button"
                            >
                                Know More
                            </a>
                        </div>
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6c9b16b405b23ceaa723993624b5a04d72ae4ecc24de105000d7d53b13b260?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                            className="object-contain self-stretch my-auto rounded-none aspect-[1.08] sm:w-2/5 hidden sm:block"
                            alt="Illustration representing e-Telecom Transformation Centre"
                        />
                    </div>
                </div>
                {/* <div className='flex flex-col sm:flex-row gap-10 items-center text-lg my-14 relative '>
                    <div className='flex flex-col sm:flex-row gap-10 items-center text-lg my-14 relative'>
                        <div className="sm:w-3/5 flex flex-col self-stretch my-auto min-w-[240px] max-md:max-w-full">
                            <h2 className="text-6xl font-bold leading-10 max-md:max-w-full max-md:text-4xl md:leading-[82px]">
                                <span className="text-indigo-900">Empowering</span>
                                <span className="text-indigo-600">Connectivity</span>
                                <br />
                                <span className="text-indigo-900">Innovating</span>
                                <span className="text-indigo-600">Tomorrow</span>
                            </h2>
                            <p className="mt-12 leading-7 text-gray-900 max-md:mt-5 max-md:max-w-full">
                                <strong>e-Telecom Transformation Centre- eTT</strong> (Formally named as
                                Tele Tech), recently setup by Department of Telecommunication, Ministry
                                of Communications, Government of India.
                            </p>
                            <a
                                href="#"
                                className="inline-block gap-2.5 self-start px-8 py-5 mt-12 leading-relaxed text-white bg-indigo-600 rounded-[35px] max-md:px-5 max-md:mt-10"
                                role="button"
                            >
                                Know More
                            </a>
                        </div>
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6c9b16b405b23ceaa723993624b5a04d72ae4ecc24de105000d7d53b13b260?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                            className="object-contain self-stretch my-auto rounded-none aspect-[1.08] sm:w-2/5 hidden sm:block"
                            alt="Illustration representing e-Telecom Transformation Centre"
                        />
                    </div>
                </div> */}
                
            {/* </Slider> */}
            </div>
        </section>
    )
}

export default HeroSlider