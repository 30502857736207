import React from 'react'
import LogoSlider from '../../pages/home/LogoSlider/LogoSlider'

const Footer = () => {
    return (
        <>
        <LogoSlider />
            {/* <div className="relative flex overflow-x-hidden bg-white">
                <section className="animate-marquee container mx-auto flex flex-row overflow-hidden gap-5 justify-between items-center py-4 bg-white bg-opacity-60 whitespace-nowrap">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dbb373ceeb8aeb8600c178fc702e9889e545810e5d2efc79c1de011a7c8c6cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.79] w-[150px]"
                        alt="Company logo"
                    />
                    <div className="flex gap-2.5 justify-center items-center self-stretch my-auto w-[150px]">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7fc312f619558f7c636b0addb1d2a0f343f70d43e5992703207dc65e6745987?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                            className="object-contain self-stretch my-auto aspect-[0.57] w-[47px]"
                            alt="Partner logo"
                        />
                    </div>
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cfc060368c6d71702bafc37f55b958d9fa69ba090649825e8007dfbf04ea15e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[2.68] w-[150px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/59a4ad25f190d06b95b54ffbbae72003e6f17aa47bf966711f39890f4503e0f6?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[3.75] w-[150px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e77fed780e961b626790ba6e788332f86959f1a9e713027895586f45b7763d?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.34] w-[150px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/56e761a36091a5a7e9c7bf571b3c194823e8db0431c37ca30f7fd46aa64240a4?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[0.82] w-[69px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4c8a2929f8dd160eb59499860d567facd43a54186855a4498a6bf76595eaf4e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/518af0e7e0a9cf210d7a900a5851568b58c6c17d6c679517d76b018db8ee05cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                        alt="Partner logo"
                    />
                </section>
                <section className="container mx-auto flex flex-row overflow-hidden gap-5 justify-between items-center py-4 bg-white bg-opacity-60 absolute top-0 animate-marquee2 whitespace-nowrap">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dbb373ceeb8aeb8600c178fc702e9889e545810e5d2efc79c1de011a7c8c6cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.79] w-[150px]"
                        alt="Company logo"
                    />
                    <div className="flex gap-2.5 justify-center items-center self-stretch my-auto w-[150px]">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b7fc312f619558f7c636b0addb1d2a0f343f70d43e5992703207dc65e6745987?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                            className="object-contain self-stretch my-auto aspect-[0.57] w-[47px]"
                            alt="Partner logo"
                        />
                    </div>
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cfc060368c6d71702bafc37f55b958d9fa69ba090649825e8007dfbf04ea15e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[2.68] w-[150px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/59a4ad25f190d06b95b54ffbbae72003e6f17aa47bf966711f39890f4503e0f6?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[3.75] w-[150px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1e77fed780e961b626790ba6e788332f86959f1a9e713027895586f45b7763d?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.34] w-[150px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/56e761a36091a5a7e9c7bf571b3c194823e8db0431c37ca30f7fd46aa64240a4?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[0.82] w-[69px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4c8a2929f8dd160eb59499860d567facd43a54186855a4498a6bf76595eaf4e?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                        alt="Partner logo"
                    />
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/518af0e7e0a9cf210d7a900a5851568b58c6c17d6c679517d76b018db8ee05cc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                        className="object-contain shrink-0 self-stretch my-auto aspect-[1.33] w-[200px]"
                        alt="Partner logo"
                    />
                </section>
            </div>   */}

            <section className="bg-stone-50">
                {/* <div className="container mx-auto flex flex-col sm:flex-row justify-center px-3 py-12 max-md:px-5">
                    <div className="flex-1 flex flex-col items-start">
                        <div className="flex gap-7 items-center max-w-full">
                            <article className="flex flex-col self-stretch py-8 pr-8 pl-2.5 my-auto max-md:pr-5 max-md:max-w-full">
                                <header className="flex flex-col justify-center items-start w-full max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/56aa980755dcc19c579e26ed95e52586c3058ea70ed45bdca5789a27473e40dc?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                                        alt="e Telecom Transformation Centre logo"
                                        className="object-contain max-w-full aspect-[2.48] w-[198px]"
                                    />
                                </header>
                                <p className="mt-2.5 text-sm leading-6 text-gray-900 max-md:max-w-full">
                                    This is an Independent Business Vertical (IBV) of CDOT renamed as
                                    e Telecom Transformation Centre- eTT (Formally named as Tele
                                    Tech), recently setup by Department of Telecommunication, Ministry
                                    of Communications, Government of India.
                                </p>

                            </article>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row flex-wrap gap-10 items-start text-lg text-gray-900 text-opacity-80 max-md:max-w-full">
                        <nav className="flex flex-col leading-relaxed">
                            <h2 className="text-2xl font-bold leading-tight text-gray-900">
                                Quick Links
                            </h2>
                            <ul className="mt-8 space-y-8">
                                <li>
                                    <a href="#about">About Us</a>
                                </li>
                                <li>
                                    <a href="#services">Services</a>
                                </li>
                                <li>
                                    <a href="#team">Our Team</a>
                                </li>
                            </ul>
                        </nav>
                        <address className="flex flex-col leading-7 max-md:max-w-full not-italic">
                            <h2 className="text-2xl font-bold leading-tight text-gray-900">
                                Contact Us
                            </h2>
                            <div className="mt-5 text-[#191825] text-lg font-medium font-['Inter'] leading-[28.80px]">
                                e-Telecom Transformation Centre
                                <br />
                                5th Floor, TEC Building
                                <br />
                                Khurshid Lal Bhawan,
                                <br />
                                Janpath, New Delhi – 110001
                                <br />
                                Phone: 011 23326141
                            </div>
                        </address>
                    </div>
                </div> */}
                <div className="w-full  px-4 py-2.5 bg-[#413783] shadow justify-start items-center gap-[421px] inline-flex">
                    <div className="container mx-auto gap-16 ">
                        <div className="text-white text-sm font-medium font-['Poppins'] leading-tight text-center">
                            © 2024 Copyright eTT    |   e-Telecom Transformation Centre, 5th Floor, TEC Building, Khurshid Lal Bhawan, Janpath, New Delhi – 110001 Phone: 011 23326141
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer