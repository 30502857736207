import React from 'react'
import Header from '../../components/Header/Header';
import HeroSlider from './Hero/HeroSlider';
import About from './About/About';
import Footer from '../../components/Footer/Footer';
import OrgStructure from './OrgStructure/OrgStructure';

const Home = () => {
    return (
        <div>
            <Header />
            {/* hero section  */}
            <HeroSlider />
            
            {/* about us */}
            <About />
            {/* services section */}
            {/* <WeDo /> */}
            {/* teams slider */}
            {/* <LeadersSlider /> */}
            {/* teams section */}
            {/* <Teams /> */}

            {/* <ImageGallery /> */}

            {/* subscribe cta */}
            {/* <section className="container mx-auto my-10 flex flex-col justify-center p-2.5 font-bold text-center pl-10 pr-12">
                <div className="sm:mx-10 flex flex-col w-full max-md:max-w-full">
                    <div className="flex items-start w-full rounded-none max-md:max-w-full relative">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9238f60ca79e7e4569d8a653344e65de05e6d555879b29af42e1ba5ec54b90fa?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                            alt=""
                            className="object-contain shrink-0 max-w-full aspect-[1.04] w-[184px] absolute -top-2 -left-8 hidden sm:block"
                        />
                        <div className="flex flex-col grow shrink-0 px-16 py-32 mt-11 basis-0 bg-[#F9C7B726] bg-opacity-10 rounded-[32px] w-fit max-md:px-5 max-md:py-24 max-md:mt-10 max-md:max-w-full">
                            <div className="flex flex-col self-center max-w-full">
                                <h2 className="text-2xl leading-tight text-indigo-600 uppercase tracking-[4.6px] max-md:max-w-full">
                                    subscribe to our newsletter
                                </h2>
                                <p className="mt-8 text-6xl text-gray-900 leading-[66px] max-md:max-w-full max-md:text-4xl max-md:leading-[53px]">
                                    Let's discover the wonders of the Telecom world
                                </p>
                            </div>
                            <form className="flex flex-wrap gap-3 items-start mt-16 w-full text-2xl leading-tight max-md:mt-10 max-md:max-w-full">
                                <div className="flex flex-wrap grow shrink gap-4 items-center px-8 py-5 bg-white border border-solid border-zinc-300 min-w-[240px] rounded-[32px] text-gray-900 text-opacity-80 w-[673px] max-md:px-5 max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/097fc46f14dad105c5f8928dd45c0c28b5a01ce382f9f472a24a4c2c465c0358?placeholderIfAbsent=true&apiKey=a019a67fa0ab4407bff3fba5c7b35349"
                                        alt=""
                                        className="object-contain shrink-0 self-stretch my-auto w-8 aspect-square"
                                    />
                                    <label htmlFor="emailInput" className="sr-only">
                                        Your Email
                                    </label>
                                    <input
                                        type="email"
                                        id="emailInput"
                                        placeholder="Your Email"
                                        aria-label="Your Email"
                                        className="bg-transparent border-none outline-none flex-grow"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="gap-4 self-stretch px-16 py-5 text-white whitespace-nowrap bg-indigo-600 min-w-[240px] rounded-[32px] max-md:px-5 w-full md:w-auto"
                                >
                                    Subscribe
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* footer */}
            {/* <OrgStructure /> */}
            <Footer />

        </div>
    );
}

export default Home